window.esign = window.esign || {};

esign.init = function () {

  $('html').removeClass('no-js').addClass('js');

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752,0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();

  esign.initMaps();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.workshopToggle();
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $nav: $('.main-nav__wrap')
  };
};

esign.navigation = function () {
  $('.main-nav__trigger').click(function(e) {
    e.preventDefault();
    $(this).next('.main-nav__wrap').slideToggle('fast');
  });

  Response.crossover('width', function() {
    if(Response.band(790)) {
      esign.cache.$nav.css('display', 'block');
    } else {
      esign.cache.$nav.css('display', 'none');
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

esign.workshopToggle = function () {
  $('.js-toggle').on('click', function () {
    var $thisWorkshop = $(this).parents('.workshop');
    $thisWorkshop.find('.workshop__toggle').toggleClass('active');
    $thisWorkshop.find('.workshop__info').toggleClass('active');
    $thisWorkshop.find('.workshop__info').slideToggle('fast');
  });
};


// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude) {

  var zoom = 15,
    disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {name: 'Styled Map'}),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  google.maps.event.addDomListener(window, 'resize', function () {
    map.setCenter(mapCenter);
    if($(window).width() > 767) {
      map.panBy(150,0);
    }
    if($(window).width() > 1200) {
      map.panBy(250,0);
    }

  });

  return map;
}

function addContactGoogleMapsMarker(map, latitude, longitude, title, externUrl) {
  var marker_size = new google.maps.Size(101, 116);
  var marker_anchor = new google.maps.Point(50, 116);
  if($(window).width()>767) {
    marker_size = new google.maps.Size(202, 232);

    marker_anchor = new google.maps.Point(101, 232);
  }


  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url:  'assets/build/images/marker.png',
      size: marker_size,
      origin: new google.maps.Point(0, 0),
      anchor: marker_anchor,
      scaledSize: marker_size
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon
    });

  google.maps.event.addListener(marker, 'click', function () {
    window.open(externUrl, '_blank');
  });

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: 'Bellemhof',
    lat: 51.089668,
    long: 3.492644,
    externUrl: 'https://goo.gl/maps/zMoRuYU9N9E2'
  };

  if ($('#map').length > 0) {
    var map = addContactGoogleMaps('map', mapData.lat, mapData.long);
    addContactGoogleMapsMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
   if($(window).width()>767) {
     map.panBy(150,0);
   }
   if($(window).width()>1200) {
     map.panBy(250,0);
   }

  }
};


// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') == false) {
        console.log('valida engin false');
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  $.ajax({
    type: 'POST',
    url:$form.attr('action'),
    data: new FormData($form[0]),
    processData: false,
    // Allows us to get file fields via JS
    contentType: false,
    success: function(data) {
      $('li').removeClass('error');
      var $result = $form.find('.result');

      if (data.errors === false) {
        $form.html(data.result);

        if (typeof ga === "function") {
          ga('send', 'pageview', $form.attr('action'));
        }

        //todo GTM trigger event

      } else {
        $result.html(data.result);
        if (data.fields) {
          $.each(data.fields, function (i, field) {
            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
          });
        }
      }

      $form.find('input[type="submit"], button').removeAttr('disabled');
    }
  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if(esign.cache.isMobile) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }
};


// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
      isMobile = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/)  ||
        deviceAgent.match(/(iemobile)/) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i)) ||
        (/OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent));

  if(isMobile) {
    $('html').addClass('mobile');
  } else {
    $('html').addClass('no-mobile');
  }

  return isMobile;
};


// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/plugins/fancybox.min',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
  'js/googlemaps-styles'
], function() {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/esign", function(){});

